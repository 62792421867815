import { template as template_6cd21db536314e4a8a662d880833a083 } from "@ember/template-compiler";
const FKText = template_6cd21db536314e4a8a662d880833a083(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
