import { template as template_b89467ad09fe426bbb32f162e1edc508 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_b89467ad09fe426bbb32f162e1edc508(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
